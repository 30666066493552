@font-face {
font-family: 'inter';
src: url(/_next/static/media/4180aacdbf94d526-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: 'inter Fallback';src: local("Arial");ascent-override: 103.70%;descent-override: 23.33%;line-gap-override: 0.00%;size-adjust: 100.28%
}.__className_311451 {font-family: 'inter', 'inter Fallback'
}.__variable_311451 {--font-inter: 'inter', 'inter Fallback'
}

@font-face {
font-family: 'menlo';
src: url(/_next/static/media/84776b98773d7459-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: 'menlo Fallback';src: local("Arial");ascent-override: 70.35%;descent-override: 17.87%;line-gap-override: 0.00%;size-adjust: 131.94%
}.__className_4b4f43 {font-family: 'menlo', 'menlo Fallback'
}.__variable_4b4f43 {--font-menlo: 'menlo', 'menlo Fallback'
}

@font-face {
font-family: 'helvetica';
src: url(/_next/static/media/1506853152b26b89-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'helvetica';
src: url(/_next/static/media/fe200430d782e53f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'helvetica';
src: url(/_next/static/media/349104fee32f8909-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'helvetica Fallback';src: local("Arial");ascent-override: 93.91%;descent-override: 21.11%;line-gap-override: 0.00%;size-adjust: 101.37%
}.__className_65860d {font-family: 'helvetica', 'helvetica Fallback'
}.__variable_65860d {--font-helvetica: 'helvetica', 'helvetica Fallback'
}

